<template>
  <div>
      <div class="position-relative">

      </div>
      <div class="right-bubble-left">
          
        </div>
        <div class="right-bubble-right">

        </div>
        <div class="right-bubble-mid">

        </div>
  </div>
</template>

<script>
export default {
    name: "probubbles",
}
</script>

<style>

</style>